<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Employee Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Employee Name"
              >
                <v-select
                  v-model="employee_id"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None"
                  :options="employeeoption"
                >
                  <template #option="{ name, profile_image, username, color, surname }">
                    <b-avatar :src="getprofileImage(profile_image)" />
                    <span :class="'font-weight-bolder text-' + color">
                      {{ name }} {{ surname }} ({{ username }})</span
                    >
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Activity Type</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Activity Type"
              >
                <v-select
                  v-model="activitytype"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  placeholder="None"
                  :options="['Local Area', 'Office', 'Out of City', 'Out of State']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Expense Purpose</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Expense Purpose"
              >
                <v-select
                  v-model="expense_purpose"
                  label="name"
                  placeholder="None"
                  :options="['For Sales', 'Promotion', 'Other']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Date</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Date">
                <flat-pickr
                  v-model="date"
                  class="form-control"
                  :config="{
                    enableTime: false,
                    dateFormat: 'd/m/Y',
                    maxDate: new Date(),
                  }"
                  style="background: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Place Destination</label>
              <b-form-input
                v-model="place"
                type="text"
                placeholder="Enter Place Destination"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>KM</label>
              <b-form-input v-model="km" type="number" placeholder="Enter KM" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Travel Fare</label>
              <b-form-input
                v-model="travelfare"
                type="number"
                placeholder="Enter Travel Fare"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Local Conveyance</label>
              <b-form-input
                v-model="localconveyance"
                type="number"
                placeholder="Enter Local Conveyance"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Food Expenses</label>
              <b-form-input
                v-model="foodexpense"
                type="number"
                placeholder="Enter Food Expenses"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Hotel Expenses</label>
              <b-form-input
                v-model="hotelexpense"
                type="number"
                placeholder="Enter Hotel Expenses"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Other Expenses</label>
              <b-form-input
                v-model="otherexpense"
                type="number"
                placeholder="Enter Other Expenses"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Details of Other Expenses</label>
              <b-form-input
                v-model="otherexpensedetails"
                type="text"
                placeholder="Enter Details of Other Expenses"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Remarks</label>
              <b-form-input v-model="remarks" type="text" placeholder="Enter Remarks" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <table class="table table-bordered">
              <thead class="text">
                <tr>
                  <th>Sr. No</th>
                  <th>Attachment Name</th>
                  <th>Attachment File</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, id) in data" :key="id">
                  <td>{{ id + 1 }}</td>
                  <td>
                    <b-form-input
                      v-model="item.attachmentname"
                      placeholder="Enter Attachment Name"
                    />
                  </td>
                  <td>
                    <b-form-file
                      v-model="item.attachmentfile"
                      placeholder="Select Attachment File"
                      drop-placeholder="Drop file here..."
                      accept="image/*"
                      @input="handleMultiFileChange($event, 'expense', id)"
                    />
                    <attachment :data="item.attachmentfile" />
                  </td>
                  <td>
                    <b-button variant="outline-danger" @click="removeRow(id)">
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <b-button variant="primary" @click="addRow" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              :disabled="flag || submitDisable"
              @click.prevent="submitForm($event)"
              class="mr-4"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormFile,
  BAvatar,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../../components/Attechment.vue";

export default {
  components: {
    BFormFile,
    PinchScrollZoom,
    flatPickr,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BAvatar,
  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      employee_id: "",
      employeeoption: [],
      activitytype: "",
      date: moment(new Date()).format("DD/MM/yyyy"),
      // nextfollowupdate: moment(new Date()).format("DD/MM/yyyy"),
      place: "",
      km: "",
      travelfare: "",
      localconveyance: "",
      foodexpense: "",
      hotelexpense: "",
      otherexpense: "",
      otherexpensedetails: "",
      remarks: "",
      data: [
        {
          attachmentname: "",
          attachmentfile: "",
        },
      ],
      flag: false,
      expense_purpose: "",
      submitDisable: false,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      imagePath: process.env.VUE_APP_IMAGE_PATH,
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Expense") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/crm/expense");
            }
          } else if (item.add !== 1) {
            this.$router.push("/crm/expense");
          }
        }
      });
    }
    this.init();
    this.ifEdit = this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image;
        return test;
      }
      return image;
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getexpenseById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      item.map((item) => {
        this.employee_id = item.employee;
        this.activitytype = item.activitytype;
        this.date = item.date;
        this.place = item.place;
        this.km = item.km;
        this.travelfare = item.travelfare;
        this.localconveyance = item.localconveyance;
        this.foodexpense = item.foodexpense;
        this.hotelexpense = item.hotelexpense;
        this.otherexpense = item.otherexpense;
        this.otherexpensedetails = item.otherexpensedetails;
        this.expense_purpose = item.expense_purpose;
        this.remarks = item.remarks;
        this.data = item.ExpenseList;
      });
    },
    async handleMultiFileChange(e, type, index) {
      this.flag = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.data[index].attachmentfile =
            process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          this.flag = false;
        })
        .catch((error) => console.log(error, "error"));
    },
    init() {
      this.getUser();
    },
    async getUser(id) {
      if (id !== undefined) {
        this.id = id;
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.employeeoption = response.data.data;
          this.employeeoption.map((item) => {
            if (item.id == id) {
              this.employee_id = item.name;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    onClickBack() {
      this.$router.push("/crm/expense");
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          data = {
            employee_id: this.employee_id.id,
            activitytype: this.activitytype,
            date: this.date,
            place: this.place,
            km: this.km,
            travelfare: this.travelfare,
            localconveyance: this.localconveyance,
            foodexpense: this.foodexpense,
            hotelexpense: this.hotelexpense,
            expense_purpose: this.expense_purpose,
            otherexpense: this.otherexpense,
            otherexpensedetails: this.otherexpensedetails,
            remarks: this.remarks,
            data: this.data,
          };
        }

        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.submitDisable = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/expense/${this.$route.params.id}`
              : `${baseApi}/expense`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((response) => {
              this.submitDisable = false;

              this.$router.push("/crm/expense");
              this.$swal({
                title: "Submited",
                text: response.data.message
                  ? `${response.data.message}`
                  : response.data.success
                  ? `${response.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              this.submitDisable = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    addRow() {
      this.data.push({
        attachmentname: "",
        attachmentfile: "",
      });
    },
    removeRow(index) {
      this.data.splice(index, 1);
    },
  },
};
</script>
